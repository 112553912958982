import React, { FC, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, Typography } from '@material-ui/core';
import AppBar from '../components/AppBar/AppBar';
import HeroContainer from '../components/HeroContainer/HeroContainer';
import Footer from '../components/Footer/Footer';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';
import { graphql, Link, navigate } from 'gatsby';
import BlogPostItem from '../components/BlogPostItem/BlogPostItem';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme: any): any => {
  return {
    root: {
      width: '100%',
      backgroundColor: '#F8F8F8',
    },
    heroTextContainer: {},
    heroText: {
      '& span': {
        display: 'block',
      },
    },
    heroTextSm: {
      fontSize: '24px',
      lineHeight: '30px',
      marginBottom: '8px',
      [theme.breakpoints.up(1200)]: {
        fontSize: '34px',
        lineHeight: '40px',
        marginBottom: '16px',
      },
    },
    heroTextXs: {
      fontSize: '17px',
      lineHeight: '24px',
      fontWeight: '400',
    },
    heroTextLg: {
      fontSize: '114px',
      lineHeight: '90px',
      marginBottom: '14px',
      [theme.breakpoints.up(1200)]: {
        fontSize: '160px',
        lineHeight: '130px',
        marginBottom: '16px',
      },
    },
    heroImageContainer: {
      position: 'relative',
      width: '100%',
      maxWidth: '340px',
      margin: '0 auto -21.3333vw',
      [theme.breakpoints.up(388)]: {
        marginBottom: '-83px',
      },
      [theme.breakpoints.up(1170)]: {
        marginBottom: '-140px',
      },
      [theme.breakpoints.up(1200)]: {
        maxWidth: '572px',
        height: '496px',
      },
    },
    heroImage: {
      display: 'block',
      width: '100%',
    },
    heroButton: {
      flex: 1,
      backgroundColor: theme.palette.primary.main,
      fontSize: 19,
      lineHeight: '28px',
      fontWeight: 500,
      padding: '8px 12px',
      width: 'fit-content',
      borderRadius: '0px 8px 8px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    sectionContent: {
      padding: '32px 24px 40px',
      maxWidth: 1440,
      margin: '0 auto',
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: 22,
        lineHeight: '26.07px',
        fontWeight: 700,
        letterSpacing: -0.5,
        textAlign: 'center',
        marginBottom: 20,
      },
      [theme.breakpoints.up(1200)]: {
        padding: '64px 24px 70px',
        maxWidth: '1218px',
        margin: '0 auto',
        '& h2': {
          fontSize: 32,
          lineHeight: '37.92px',
          marginBottom: theme.spacing(6),
        },
      },
    },
    blogPostTitle: {
      fontSize: '24px',
      lineHeight: '30px',
      fontWeight: 700,
      color: theme.palette.primary.main,
      margin: '0 0 24px',
    },
    blogPosts: {
      [theme.breakpoints.up(697)]: {
        padding: '0',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '24px',
        maxWidth: '1218px',
        margin: '0 auto 40px',
      },
    },
    postContainer: {
      marginBottom: '24px',
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.up(697)]: {
        padding: 0,
        margin: 0,
        flex: '0 0 calc(50% - 12px)',
      },
      [theme.breakpoints.up(1025)]: {
        flex: '0 0 calc(33.333333% - 16px)',
      },
    },
  };
});

const NotFoundPage: FC<any> = ({ data, }) => {
  const classes = (useStyles() as any);
  const { t } = useTranslation();
  const blogPosts = data?.allPrismicBlogPost?.edges;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('_branch_match_id')) {
      navigate(`/download?${queryParams.toString()}`);
    }
  }, [])
  return (
    <>
      <AppBar />
      <main className={classes.root}>
        <HeroContainer>
          <div>
            <div className={classes.heroTextContainer}>
              <Typography
                className={classes.heroText}
                variant="h1"
              >
                <span className={classes.heroTextSm}>{t('404Page.heroTextSm')}</span>
                <span className={classes.heroTextLg}>{t('404Page.heroTextLg')}</span>
                <span className={classes.heroTextXs}>{t('404Page.heroTextXs')}</span>
              </Typography>
            </div>
            <Button
              className={classes.heroButton}
              variant="contained"
              color="primary"
              component={Link}
              to="/"
            >
              {t('404Page.takeMeHomeCTA')}
            </Button>
          </div>
          <div className={classes.heroImageContainer}>
            <img
              className={classes.heroImage}
              src="/img/404/ghost.png"
              alt={t('404Page.heroImage.alt')}
            />
          </div>
        </HeroContainer>
        <div className={classes.sectionContent}>
          <h3 className={classes.blogPostTitle}>{t('404Page.youMightLike')}</h3>
          <div className={classes.blogPosts}>
            {blogPosts?.map(({ node: blogPost }, index) => {
              return (
                <>
                  <div className={classes.postContainer}>
                    <BlogPostItem
                      key={blogPost?.id}
                      id={blogPost?.id}
                      image={blogPost?.data?.image}
                      title={blogPost?.data?.title?.text}
                      date={blogPost?.data?.publish_date}
                      tags={blogPost?.tags}
                      url={`/blog/${blogPost?.slugs[0]}`}
                    />
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default withPrismicPreview(NotFoundPage);

export const query = graphql`
  query NotFoundPageQuery($language: String) {
    allPrismicBlogPost(limit: 3, sort: { fields: data___publish_date, order: DESC }) {
      edges {
        node {
          id
          prismicId
          tags
          slugs
          data {
            title {
              text
            }
            subtitle {
              text
            }
            image {
              url
              alt
              dimensions {
                width
                height
              }
              gatsbyImageData(width: 960)
            }
            publish_date
          }
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
